// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-om-at-bygge-hus-js": () => import("./../../../src/pages/om-at-bygge-hus.js" /* webpackChunkName: "component---src-pages-om-at-bygge-hus-js" */),
  "component---src-pages-om-mig-js": () => import("./../../../src/pages/om-mig.js" /* webpackChunkName: "component---src-pages-om-mig-js" */),
  "component---src-pages-projekter-js": () => import("./../../../src/pages/projekter.js" /* webpackChunkName: "component---src-pages-projekter-js" */),
  "component---src-pages-referencer-js": () => import("./../../../src/pages/referencer.js" /* webpackChunkName: "component---src-pages-referencer-js" */),
  "component---src-templates-template-js": () => import("./../../../src/templates/template.js" /* webpackChunkName: "component---src-templates-template-js" */)
}

